import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import Slide1bg from '../images/version5/Slide1bg.mp4'
import Slide2Logo from '../images/version5/Slide2LogoBG.png'
import Slide3Gif from '../images/version5/Slide3.gif'
import Slide3Bg from '../images/version5/Slide3Bg.png'
import Slide4Gif from '../images/version5/Slide4.gif'
import Slide6 from '../images/version5/Slide6.png'
import { Link } from "react-router-dom";
import Config from '../helper/Config';

export default function LandingPage4(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("Home", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                    <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                </Link>
                <Header page="home" />
            </div>
        </div>
        <div style={{ marginTop: 100 }} className='main-container version5'>
            <div className='slide slide1'>
                <video autoPlay muted loop>
                    <source src={Slide1bg} type="video/mp4" />
                </video>

                <div className='p-5 d-flex flex-column slide1-content'>
                    {/* <div className='logo'>
                        <img className='img-fluid' src={Logo} alt={"logo"} />
                    </div> */}

                    <div>
                        <h1 className='text-uppercase'>
                            agentic ai <br/>
                            systems engineering  <br/>
                            RESEARCH <br/> 
                            <br/>
                            to
                        </h1>
                    </div>
                    <div className='px-5'>
                        <ul>
                            <li>Leapfrog technology burdens</li>
                            <li>Lower resource overhead</li>
                            <li>Capture process efficiencies</li>
                            <li>Preserve institutional knowledge</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='slide slide2'>
                <video autoPlay muted loop>
                    <source src={Slide1bg} type="video/mp4" />
                </video>
                <div className='p-5 d-flex flex-column slide2-content'>
                    <div className='d-flex'>
                        <div className='flex-1'>
                            <h2>We Address Three Main AI Challenges <br/>
                                with our Teqit Platform</h2>
                        </div>
                        <div className='logo'>
                            <img className='img-fluid' src={Slide2Logo} alt='logo' />
                        </div>
                    </div>
                    <div className='d-flex boxes my-5'>
                        <div className='box'>
                            <div className='header'>
                                <ul>
                                    <li>AI Models lack domain specific knowledge</li>
                                </ul>
                            </div>
                            <div className='body'>
                                Unlike traditional software systems, Teqit is uniquely built from the ground up on an actor model to efficiently deliver any type of domain specific knowledge through message passing asynchronously.
                            </div>
                        </div>
                        <div className='box'>
                            <div className='header'>
                                <ul>
                                    <li>AI Models can be factually incorrect, misleading, or entirely fabricated</li>
                                </ul>
                            </div>
                            <div className='body'>
                                The integration of large language models into Teqit has established natural language comprehension for the actors. The dialogue between natural language comprehension and symbolic reasoning fluidly is the key differentiator.
                            </div>
                        </div>
                        <div className='box'>
                            <div className='header'>
                                <ul>
                                    <li>AI Models can be inconsistent and superficial in their responses</li>
                                </ul>
                            </div>
                            <div className='body'>
                            By using guardrails like human in the loop mechanisms, alongside causal and semantic reasoning that is based on domain and flow ontologies.
                            Agentic Systems built on Teqit delivers 100% goal accuracy. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='slide slide3 d-flex align-items-center' style={{ backgroundImage: `url(${Slide3Bg})` }}>
                <div className='d-flex p-5'>
                    <div className='flex-1'>
                        <img className="img-fluid" src={Slide3Gif} alt={"Animation"} />
                    </div>
                    <div className='flex-1 d-flex align-items-center slide3-content p-5'>
                        Agentic AI systems are artificial intelligence (AI) systems that can perform tasks, make decisions, and interact with their environment. They can autonomously pursue complex goals and workflows, and are capable of adaptive execution and planning.
                    </div>
                </div>
            </div>
            <div className='slide slide4' style={{ backgroundImage: `url(${Slide3Bg})` }}>
                <div className='p-5 d-flex slide4-content'>
                    <div className='flex-1 p-5'>
                        <div className='content1'>
                            Agentic AI <br />
                            Needs Planning <br />
                            & Reasoning
                        </div>
                        <div className='py-5'>
                            <img className="img-fluid" src={Slide4Gif} alt={"Animation"} style={{ height: "45vh" }} />
                        </div>
                    </div>
                    <div className='flex-1 d-flex flex-column align-items-center content2 p-5'>
                        <div className='text-right logo'>
                            <img className='img-fluid' src={Slide2Logo} alt='logo' />
                        </div>
                        <div className='d-flex flex-column flex-1'>
                            <div className='box d-flex align-items-center flex-1'>
                                <ul>
                                    <li>Teqit helps organizations develop and deploy expert agents for planning and reasoning.</li>
                                </ul>
                            </div>
                            <div className='box d-flex align-items-center flex-1'>
                                <ul>
                                    <li>Teqit then brings Agentic AI systems online rapidly with ease and simplicity.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='slide slide5'>
                <video autoPlay muted loop>
                    <source src={Slide1bg} type="video/mp4" />
                </video>

                <div className='p-5 d-flex slide5-content align-items-center '>
                    <div className='flex-1 content1'>
                        <div className='mb-5'>
                            Teqit is being used as a virtualized cloud computing environment for 15 software platforms in Mexico, Africa, South Asia and the United States.
                        </div>
                        <div className='mb-5'>
                            Teqit use cases include intelligent document processing, healthcare management, itinerary planning, supply chain management, incident reporting and e-commerce.
                        </div>
                        <div className='mb-5'>
                            Builtin are collaboration, communication and execution.
                        </div>
                    </div>
                    <div className='flex-1 p-5'>
                        <div className='text-right logo'>
                            <img className='img-fluid' src={Slide2Logo} alt='logo' />
                        </div>
                        <div className=''>
                            <img className='img-fluid' src={Slide6} alt='logo' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
